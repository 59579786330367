import {
  Button,
  Center,
  Container,
  HStack,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import type { ProductBannerFragment } from "generated";
import Image from "next/image";
import type { FunctionComponent } from "react";
import { FaBookOpen, FaShoppingCart } from "react-icons/fa";
import tinycolor from "tinycolor2";

export type ProductBannerProps = ProductBannerFragment;

export const ProductBanner: FunctionComponent<ProductBannerProps> = ({
  product,
}) => {
  const backgroundColor = tinycolor(product.color.hex)
    .lighten(15)
    .toHexString();

  return (
    <Center backgroundColor={backgroundColor} paddingY={5}>
      <Container maxWidth="container.lg">
        <Stack
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          spacing={10}
        >
          <HStack>
            <Image
              src={product.logo.url}
              alt={product.logo.alt}
              title={product.logo.title}
              style={{ filter: "brightness(0%)" }}
              width={30}
              height={30}
            />
            <Text textStyle="product">{product.name}</Text>
          </HStack>
          <Text as="b" fontSize="lg">
            {product.slogan}
          </Text>
          <Wrap justify="center">
            <WrapItem>
              <Button
                as="a"
                leftIcon={<FaBookOpen />}
                href={product.documentationUrl}
              >
                Docs
              </Button>
            </WrapItem>
            <WrapItem>
              <Button
                as="a"
                leftIcon={<FaShoppingCart />}
                href={product.purchaseUrl}
              >
                Purchase
              </Button>
            </WrapItem>
          </Wrap>
        </Stack>
      </Container>
    </Center>
  );
};
