import { Box, Center, Stack, Text } from "@chakra-ui/react";
import type { FigureFragment } from "generated";
import Image from "next/image";
import type { FunctionComponent } from "react";

export type FigureProps = FigureFragment;

export const Figure: FunctionComponent<FigureProps> = ({
  image,
  isLarge,
  isCaptioned,
  hasShadow,
}) => {
  return (
    <Center>
      <Stack spacing={10} align="center">
        <Box shadow={hasShadow ? "md" : "none"} rounded="md" overflow="hidden">
          <Image
            src={image.url}
            alt={image.alt}
            title={image.title}
            width={image.width || 500}
            height={image.height || 500}
          />
        </Box>
        {isCaptioned && (
          <Text as="i" fontSize="sm" color="secondary">
            {image.title}
          </Text>
        )}
      </Stack>
    </Center>
  );
};
