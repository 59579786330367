import { AspectRatio, Box } from "@chakra-ui/react";
import MuxPlayer from "@mux/mux-player-react/lazy";
import type { VideoFragment } from "generated";
import type { FunctionComponent } from "react";

export type VideoProps = VideoFragment;

export const Video: FunctionComponent<VideoProps> = ({ asset }) => {
  const aspectRatio = 16 / 9;

  return (
    <AspectRatio ratio={aspectRatio}>
      <Box>
        <MuxPlayer
          style={{ aspectRatio }}
          playbackId={asset.video.muxPlaybackId}
          thumbnailTime={(asset.customData?.thumbnailTime as number) || 0}
          metadata={{
            video_id: asset.id,
            video_title: asset.title,
          }}
        />
      </Box>
    </AspectRatio>
  );
};
