import {
  Box,
  Card,
  CardBody,
  Grid,
  Heading,
  Link,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import type { ArticlePreviewFragment } from "generated";
import { useDate } from "hooks";
import Image from "next/image";
import NextLink from "next/link";
import type { FunctionComponent } from "react";

export type AritlcPreviewProps = ArticlePreviewFragment;

export const ArticlePreview: FunctionComponent<AritlcPreviewProps> = ({
  article,
}) => {
  const [createdAt, createdAtString] = useDate(article._createdAt);
  const [updatedAt, updatedAtString] = useDate(article._updatedAt);

  return (
    <LinkBox>
      <Card overflow="hidden">
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 2fr",
            lg: "1fr 3fr",
          }}
        >
          <Box minHeight={200} position="relative">
            <Image
              src={
                article.asset.video
                  ? `${article.asset.video.thumbnailUrl}?time=${article.asset.customData?.thumbnailTime}`
                  : article.asset.url
              }
              title={article.asset.title}
              alt={article.asset.alt}
              style={{ objectFit: "cover" }}
              sizes="100vw, 50vw, 33vw"
              fill
            />
          </Box>
          <CardBody>
            <Stack alignItems="start">
              <Text
                color="secondary"
                fontSize="sm"
              >{`Published ${createdAtString}${
                createdAt !== updatedAt ? `, Updated ${updatedAtString}` : ""
              }`}</Text>
              <NextLink
                href={`/articles/${article.slug}`}
                passHref
                legacyBehavior
              >
                <LinkOverlay>
                  <Heading>{article.name}</Heading>
                </LinkOverlay>
              </NextLink>
              <NextLink
                href={`/people/${article.author.slug}`}
                passHref
                legacyBehavior
              >
                <Link fontSize="sm" color="secondary">
                  {article.author.name}
                </Link>
              </NextLink>
              <Text noOfLines={3}>{article.description}</Text>
            </Stack>
          </CardBody>
        </Grid>
      </Card>
    </LinkBox>
  );
};
