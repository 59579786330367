import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const useDate = (
  dateConfig: dayjs.ConfigType,
  format: string = "MM MMM YYYY"
): [dayjs.Dayjs, string] => {
  const [date, setDate] = useState(dayjs(dateConfig));
  const [dateString, setDateString] = useState<string>(date.format(format));

  useEffect(() => {
    setDate(dayjs(dateConfig));
  }, [dateConfig]);

  useEffect(() => {
    setDateString(date.format(format));
  }, [date, format]);

  return [date, dateString];
};
