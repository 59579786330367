import { Button, HStack, Text } from "@chakra-ui/react";
import Link from "next/link";
import type { FunctionComponent } from "react";

export interface PreviewBannerProps {
  preview: boolean;
}

export const PreviewBanner: FunctionComponent<PreviewBannerProps> = ({
  preview,
}) => {
  if (!preview) {
    return null;
  }

  return (
    <HStack
      spacing={5}
      padding={5}
      justify="center"
      backgroundColor="background"
      zIndex={1}
      position="sticky"
      top={0}
    >
      <Text>You are currently in preview mode.</Text>
      <Link href="/api/end-preview" prefetch={false} passHref legacyBehavior>
        <Button as="a">Exit Preview Mode</Button>
      </Link>
    </HStack>
  );
};
