import { Box, Flex } from "@chakra-ui/react";
import type { ColumnLayoutFragment } from "generated";
import type { FunctionComponent } from "react";
import { ArticlePreview } from "./article-preview";
import { ContactForm } from "./contact-form";
import { ContactPreview } from "./contact-preview";
import { Discount } from "./discount";
import { Figure } from "./figure";
import { File } from "./file";
import { ImageGrid } from "./image-grid";
import { PagePreview } from "./page-preview";
import { PersonPreview } from "./person-preview";
import { Pricing } from "./pricing";
import { ProductPreview } from "./product-preview";
import { Text } from "./text";
import { Video } from "./video";

export type ColumnLayoutProps = ColumnLayoutFragment;

export const ColumnLayout: FunctionComponent<ColumnLayoutProps> = ({
  columns,
  isReversed,
}) => {
  const blocks = columns.map((block) => {
    switch (block.__typename) {
      case "VideoRecord":
        return <Video key={block.id} {...block} />;
      case "ArticlePreviewRecord":
        return <ArticlePreview key={block.id} {...block} />;
      case "ProductPreviewRecord":
        return <ProductPreview key={block.id} {...block} />;
      case "PagePreviewRecord":
        return <PagePreview key={block.id} {...block} />;
      case "ImageGridRecord":
        return <ImageGrid key={block.id} {...block} />;
      case "TextRecord":
        return <Text key={block.id} {...block} />;
      case "FileRecord":
        return <File key={block.id} {...block} />;
      case "ContactFormRecord":
        return <ContactForm key={block.id} {...block} />;
      case "ContactPreviewRecord":
        return <ContactPreview key={block.id} {...block} />;
      case "PersonPreviewRecord":
        return <PersonPreview key={block.id} {...block} />;
      case "FigureRecord":
        return <Figure key={block.id} {...block} />;
      case "PricingRecord":
        return <Pricing key={block.id} {...block} />;
      case "DiscountRecord":
        return <Discount key={block.id} {...block} />;
    }
  });

  return (
    <Flex
      direction={{ base: isReversed ? "column-reverse" : "column", md: "row" }}
      gap={10}
      alignItems="center"
    >
      {blocks.map((b) => (
        <Box key={b?.key} flex="1">
          {b}
        </Box>
      ))}
    </Flex>
  );
};
