import { Box, DarkMode as ChakraDarkMode } from "@chakra-ui/react";
import type { FunctionComponent, PropsWithChildren } from "react";

export const DarkMode: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ChakraDarkMode>
      <Box backgroundColor="chakra-body-bg" color="chakra-body-text">
        {children}
      </Box>
    </ChakraDarkMode>
  );
};
