import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { Button, Center, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import type { ContactPreviewFragment } from "generated";
import { usePhoneNumber } from "hooks";
import type { FunctionComponent } from "react";

export type ContactPreviewProps = ContactPreviewFragment;

export const ContactPreview: FunctionComponent<ContactPreviewProps> = ({
  contact,
}) => {
  const [phoneNumber, phoneNumberUrl] = usePhoneNumber(contact.phoneNumber);

  return (
    <Center>
      <Stack spacing={5}>
        <Heading>{contact.name}</Heading>
        <Stack spacing={5} as="address">
          <Text fontSize="sm" color="secondary">
            {contact.physicalAddress.number}
            &nbsp;
            {contact.physicalAddress.street}
            <br />
            {contact.physicalAddress.unit && (
              <>
                {contact.physicalAddress.unit}
                <br />
              </>
            )}
            {contact.physicalAddress.city}, {contact.physicalAddress.state}
            &nbsp;
            {contact.physicalAddress.zipCode}
          </Text>
          <Flex gap={5}>
            <Stack align="center">
              <Button
                leftIcon={<EmailIcon />}
                as="a"
                href={`mailto:${contact.emailAddress}`}
              >
                Email
              </Button>
              <Text color="secondary" fontSize="xs">
                {contact.emailAddress}
              </Text>
            </Stack>
            <Stack align="center">
              <Button leftIcon={<PhoneIcon />} as="a" href={phoneNumberUrl}>
                Call
              </Button>
              <Text color="secondary" fontSize="xs">
                {phoneNumber}
              </Text>
            </Stack>
          </Flex>
        </Stack>
      </Stack>
    </Center>
  );
};
