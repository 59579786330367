import {
  Box,
  Card,
  CardBody,
  Grid,
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import type { PagePreviewFragment } from "generated";
import Image from "next/image";
import Link from "next/link";
import type { FunctionComponent } from "react";

export type PagePreviewProps = PagePreviewFragment;

export const PagePreview: FunctionComponent<PagePreviewProps> = ({ page }) => {
  return (
    <LinkBox>
      <Card direction={{ base: "column", md: "row" }} overflow="hidden">
        <Grid
          width="100%"
          templateColumns={{
            base: "1fr",
            md: "1fr 2fr",
            lg: "1fr 3fr",
          }}
        >
          <Box minHeight={200} position="relative">
            <Image
              src={page.image.url}
              title={page.image.title}
              alt={page.image.alt}
              style={{ objectFit: "cover" }}
              sizes="100vw, 50vw, 33vw"
              fill
            />
          </Box>
          <Stack spacing={0}>
            <CardBody>
              <Stack alignItems="start">
                <Link href={`/${page.slug}`} passHref legacyBehavior>
                  <LinkOverlay>
                    <Heading>{page.name}</Heading>
                  </LinkOverlay>
                </Link>
                <Text>{page.description}</Text>
              </Stack>
            </CardBody>
          </Stack>
        </Grid>
      </Card>
    </LinkBox>
  );
};
