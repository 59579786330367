import { ChevronRightIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Stack,
  Button,
} from "@chakra-ui/react";
import { DarkMode, ProductLink } from "components";
import type { NavigationFragment } from "generated";
import Image from "next/image";
import Link from "next/link";
import type { FunctionComponent } from "react";

export type NavigationProps = NavigationFragment;

export const Navigation: FunctionComponent<NavigationProps> = ({
  logo,
  pages,
  products,
  homePage,
  callToActionProduct,
  callToActionText,
  callToActionUrl,
}) => {
  const pageLinks = pages.map(({ id, slug, description, name }) => (
    <Link key={id} href={`/${slug}`} passHref legacyBehavior>
      <ChakraLink
        title={description}
        color="primary"
        fontWeight={600}
        fontSize="lg"
      >
        {name}
      </ChakraLink>
    </Link>
  ));
  const productLinks = products.map(({ id, ...rest }, i) => (
    <ProductLink key={id} {...rest} />
  ));
  const ctaButton = (
    <Button
      colorScheme="blue"
      color={callToActionProduct.color.hex}
      leftIcon={
        <Image
          src={callToActionProduct.logo.url}
          alt={callToActionProduct.logo.alt}
          width={20}
          height={20}
        />
      }
      href={callToActionUrl}
      rightIcon={<ChevronRightIcon />}
      as={Link}
    >
      {callToActionText}
    </Button>
  );

  return (
    <DarkMode>
      <Stack
        direction="row"
        justifyContent="space-between"
        align="center"
        paddingY={5}
        paddingX={{ base: 5, xl: 14 }}
        shadow="md"
        zIndex={100}
      >
        <Link href={`/${homePage.slug}`}>
          <Image src={logo.url} alt="JCore Logo" width={70} height={30} />
        </Link>
        <Show above="lg">
          <Stack direction="row" spacing={{ base: 5, xl: 14 }}>
            {productLinks}
          </Stack>
          <Stack spacing={5} direction="row" align="center">
            {pageLinks}
            {ctaButton}
          </Stack>
        </Show>
        <Show above="sm">
          <Show below="lg">
            <Stack spacing={5} direction="row" align="center">
              {pageLinks}
            </Stack>
          </Show>
        </Show>
        <Show below="lg">
          <Stack spacing={5} direction="row" align="center">
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Menu"
                icon={<HamburgerIcon />}
              />
              <MenuList zIndex={20} padding={10}>
                <Stack spacing={10}>
                  {productLinks}
                  <Show below="sm">
                    <Stack spacing={5}>{pageLinks}</Stack>
                  </Show>
                </Stack>
              </MenuList>
            </Menu>
            {ctaButton}
          </Stack>
        </Show>
      </Stack>
    </DarkMode>
  );
};
