import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import {
  ArticlePreview,
  ContactForm,
  ContactPreview,
  DarkMode,
  Figure,
  File,
  ImageGrid,
  PagePreview,
  PersonPreview,
  ProductBanner,
  ProductPreview,
  Text,
  Video,
} from "components";
import type { SectionFragment } from "generated";
import type { FunctionComponent } from "react";
import { ColumnLayout } from "./column-layout";
import { Discount } from "./discount";
import { Pricing } from "./pricing";
import { ArticleList } from "./article-list";

export type SectionProps = SectionFragment;

export const Section: FunctionComponent<SectionProps> = ({
  isDark,
  isPadded,
  blocks,
  name,
}) => {
  const content = (
    <Box paddingY={isPadded ? { base: 10, md: 14 } : 0}>
      <Stack shouldWrapChildren spacing={{ base: 10, md: 14 }}>
        {name && (
          <Container maxWidth="container.lg">
            <Heading variant="h1" textAlign="center">
              {name}
            </Heading>
          </Container>
        )}
        {blocks.map((block) => {
          switch (block.__typename) {
            case "VideoRecord":
              return (
                <Container key={block.id} maxWidth="container.xl">
                  <Video {...block} />
                </Container>
              );
            case "ArticlePreviewRecord":
              return (
                <Container key={block.id} maxWidth="container.lg">
                  <ArticlePreview {...block} />
                </Container>
              );
            case "ProductPreviewRecord":
              return (
                <Container key={block.id} maxWidth="container.xl">
                  <ProductPreview {...block} />
                </Container>
              );
            case "PagePreviewRecord":
              return (
                <Container key={block.id} maxWidth="container.lg">
                  <PagePreview {...block} />
                </Container>
              );
            case "ImageGridRecord":
              return (
                <Container key={block.id} maxWidth="container.xl">
                  <ImageGrid {...block} />
                </Container>
              );
            case "TextRecord":
              return (
                <Container key={block.id} maxWidth="container.lg">
                  <Text {...block} />
                </Container>
              );
            case "FileRecord":
              return (
                <Container key={block.id} maxWidth="sm">
                  <File {...block} />
                </Container>
              );
            case "ContactFormRecord":
              return (
                <Container key={block.id} maxWidth="container.sm">
                  <ContactForm {...block} />
                </Container>
              );
            case "ContactPreviewRecord":
              return (
                <Container key={block.id} maxWidth="container.sm">
                  <ContactPreview {...block} />
                </Container>
              );
            case "PersonPreviewRecord":
              return (
                <Container key={block.id} maxWidth="container.lg">
                  <PersonPreview {...block} />
                </Container>
              );
            case "FigureRecord":
              return (
                <Container
                  key={block.id}
                  maxWidth={block.isLarge ? "container.xl" : "container.md"}
                >
                  <Figure {...block} />
                </Container>
              );
            case "ProductBannerRecord":
              return <ProductBanner key={block.id} {...block} />;
            case "PricingRecord":
              return (
                <Container key={block.id}>
                  <Pricing {...block} />
                </Container>
              );
            case "DiscountRecord":
              return (
                <Container key={block.id}>
                  <Discount {...block} />
                </Container>
              );
            case "ColumnLayoutRecord":
              return (
                <Container key={block.id} maxWidth="container.xl">
                  <ColumnLayout {...block} />
                </Container>
              );
            case "ArticleListRecord":
              return (
                <Container key={block.id} maxWidth="container.xl">
                  <ArticleList {...block} />
                </Container>
              );
          }
        })}
      </Stack>
    </Box>
  );

  return isDark ? <DarkMode>{content}</DarkMode> : content;
};
