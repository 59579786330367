import { Button, Heading, Stack, StackItem, Text } from "@chakra-ui/react";
import { ProductLink } from "components";
import type { ProductPreviewFragment } from "generated";
import Image from "next/image";
import Link from "next/link";
import type { FunctionComponent } from "react";
import { FaAngleRight } from "react-icons/fa";

export type ProductPreviewProps = ProductPreviewFragment;

export const ProductPreview: FunctionComponent<ProductPreviewProps> = ({
  isFlipped,
  product,
  productLink,
}) => {
  return (
    <Stack
      spacing={{ base: 10, lg: 14 }}
      direction={{
        base: "column",
        lg: isFlipped ? "row-reverse" : "row",
      }}
      alignItems="center"
    >
      <Image
        src={product.image.url}
        alt={product.image.alt}
        title={product.image.title}
        width={600}
        height={600}
      />
      <Stack shouldWrapChildren spacing={5} alignItems="start">
        <ProductLink hidePopup {...productLink} />
        <Heading>{product.slogan}</Heading>
        <Text>{product.description}</Text>
        <StackItem alignSelf="end" paddingY={5}>
          <Link href={`/${product.page.slug}`} passHref legacyBehavior>
            <Button
              rightIcon={<FaAngleRight />}
              as="a"
            >{`About ${product.name}`}</Button>
          </Link>
        </StackItem>
      </Stack>
    </Stack>
  );
};
