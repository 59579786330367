import { Stack } from "@chakra-ui/react";
import type { TextFragment } from "generated";
import type { FunctionComponent } from "react";
import { StructuredText, StructuredTextGraphQlResponse } from "react-datocms";
import { nodeRules } from "utilities";

export type TextProps = TextFragment;

export const Text: FunctionComponent<TextProps> = ({ content }) => {
  return (
    <Stack spacing={5}>
      <StructuredText
        customNodeRules={nodeRules}
        data={content.value as StructuredTextGraphQlResponse}
      />
    </Stack>
  );
};
