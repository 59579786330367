import { Wrap, WrapItem } from "@chakra-ui/react";
import type { ImageGridFragment } from "generated";
import Image from "next/image";
import type { FunctionComponent } from "react";

export type ImageGridProps = ImageGridFragment;

export const ImageGrid: FunctionComponent<ImageGridProps> = ({
  isGrayscale,
  isLarge,
  images,
}) => {
  const size = isLarge ? 200 : 70;

  return (
    <Wrap justify="center" spacing={10}>
      {images.map(({ id, url, alt, title }) => (
        <WrapItem key={id} alignItems="center" justifyItems="center">
          <Image
            src={url}
            alt={alt}
            title={title}
            width={size}
            height={size}
            style={{
              filter: isGrayscale
                ? "invert(50%) sepia(100%) grayscale(100%) contrast(100%)"
                : "none",
            }}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
};
