import {
  Button,
  Center,
  Skeleton,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { ArticleListFragment, ArticleListQuery } from "generated";
import { FunctionComponent, useEffect, useState } from "react";
import { ArticlePreview } from "./article-preview";

export type ArticleListProps = ArticleListFragment;

export const ArticleList: FunctionComponent<ArticleListProps> = ({ tags }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const [currentArticles, setCurrentArticles] = useState<
    ArticleListQuery["allArticles"]
  >([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams();

        if (selectedTagId !== null) {
          params.append("tagIds", selectedTagId);
        } else {
          tags.forEach((tag) => params.append("tagIds", tag.id));
        }

        const response = await fetch(`/api/articles?${params.toString()}`, {
          method: "GET",
        });

        const data: ArticleListQuery = await response.json();
        setCurrentArticles(data.allArticles);
      } catch (e) {
        toast({
          title: "Error fetching articles.",
          status: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [selectedTagId, tags, toast]);

  return (
    <Stack spacing={4}>
      {tags.length > 1 && (
        <Stack direction="row" spacing={4}>
          <Button
            onClick={() => setSelectedTagId(null)}
            variant={selectedTagId === null ? "solid" : "outline"}
          >
            All
          </Button>
          {tags.map((tag) => (
            <Button
              key={tag.id}
              onClick={() => setSelectedTagId(tag.id)}
              variant={selectedTagId === tag.id ? "solid" : "outline"}
            >
              {tag.name}
            </Button>
          ))}
        </Stack>
      )}
      {isLoading && !currentArticles.length && (
        <Center padding={10}>
          <Spinner />
        </Center>
      )}
      {currentArticles.map((article) => (
        <ArticlePreview key={article.id} article={article} />
      ))}
    </Stack>
  );
};
