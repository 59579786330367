import { Box } from "@chakra-ui/react";
import type { FunctionComponent, PropsWithChildren } from "react";
import tinycolor from "tinycolor2";

export interface HighlighterProps {
  color: string;
}

export const Highlighter: FunctionComponent<
  PropsWithChildren<HighlighterProps>
> = ({ children, color }) => {
  const ac = tinycolor(color).lighten(20).toRgb();
  const backgroundImage = `linear-gradient(to right, rgba(${ac.r}, ${ac.g}, ${ac.b}, 0.1), rgba(${ac.r}, ${ac.g}, ${ac.b}, 0.7) 4%, rgba(${ac.r}, ${ac.g}, ${ac.b}, 0.3))`;

  return (
    <Box
      style={{
        margin: "0 -0.4em",
        padding: "0.1em 0.4em",
        borderRadius: "0.8em 0.3em",
        background: "transparent",
        boxDecorationBreak: "clone",
        backgroundImage,
      }}
    >
      {children}
    </Box>
  );
};
