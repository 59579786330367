import { WebClient } from "@slack/web-api";
import type { NextApiRequest, NextApiResponse } from "next";
import * as yup from "yup";
import { ValidationError } from "yup";

export const contactRequestValidationSchema = yup.object({
  name: yup.string().required(),
  emailAddress: yup.string().email().required(),
  message: yup.string().required(),
});

const tokenValidationSchema = yup.object({
  token: yup.string().required(),
});

const handler = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const body = await contactRequestValidationSchema
      .concat(tokenValidationSchema)
      .validate(req.body);
    const recaptchaSecret = process.env.RECAPTCHA_SECRET_KEY;

    if (!recaptchaSecret) {
      throw "Missing required environment variable RECAPTCHA_SECRET_KEY";
    }

    const response = await fetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${recaptchaSecret}&response=${body.token}`,
      {
        method: "POST",
      }
    );

    const { success } = await response.json();

    if (!success) {
      throw "Recaptcha verification failed.";
    }

    const channel = process.env.SLACK_CHANNEL_ID;
    const token = process.env.SLACK_TOKEN;

    if (!channel) {
      throw "Missing required environment variable SLACK_CHANNEL_ID";
    }

    if (!token) {
      throw "Missing required environment variable SLACK_TOKEN";
    }

    const client = new WebClient(token);

    await client.chat.postMessage({
      channel,
      text: `New contact form submission from ${body.name} (${body.emailAddress}): ${body.message}`,
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: "New Contact Form Submission",
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*From:* ${body.name} <<mailto:${body.emailAddress}|${body.emailAddress}>>\n*Message:* ${body.message}`,
          },
        },
      ],
    });

    res.status(200).send({});
  } catch (e) {
    if (e instanceof ValidationError) {
      res.status(400).send(e.message);
    }

    res.status(500).send("An unknown server error occured.");
  }
};

export default handler;
