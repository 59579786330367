import { Box, Center, Text } from "@chakra-ui/react";
import type { DiscountFragment } from "generated";
import type { FunctionComponent } from "react";

export type DiscountProps = DiscountFragment;

export const Discount: FunctionComponent<DiscountProps> = ({ text }) => {
  return (
    <Center>
      <Box
        maxWidth={400}
        backgroundColor="yellow.200"
        padding={5}
        borderTopStyle="dashed"
        borderTopWidth={5}
        borderTopColor="blackAlpha.500"
        borderBottomRadius="md"
        textAlign="center"
      >
        <Text as="b">{text}</Text>
      </Box>
    </Center>
  );
};
