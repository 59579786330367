import {
  Button,
  Card,
  Center,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Stat,
  StatHelpText,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import type { PricingFragment } from "generated";
import type { FunctionComponent } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";

export type PricingProps = PricingFragment;

export const Pricing: FunctionComponent<PricingProps> = ({
  pricingName,
  addOns,
  quantityLabel,
  unitLabel,
  features,
  nonFeatures,
  product,
  prices,
}) => {
  const getPriceString = (price: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <Card padding={{ base: 5, md: 10 }}>
      <Stack spacing={10}>
        {pricingName && <Heading>{pricingName}</Heading>}
        {prices.length > 1 ? (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th fontSize="lg" textTransform="none">
                    {quantityLabel}
                  </Th>
                  <Th
                    fontSize="lg"
                    textTransform="none"
                    isNumeric
                  >{`Per ${unitLabel}`}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {prices.map((price) => (
                  <Tr key={price.id}>
                    <Td>
                      {price.quantityMax
                        ? `${price.quantityMin} - ${price.quantityMax}`
                        : `${price.quantityMin} +`}
                    </Td>
                    <Td isNumeric>{getPriceString(price.unitPrice)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Stat textAlign="center">
            {prices[0].quantityMax
              ? `${prices[0].quantityMin} - ${prices[0].quantityMax} ${quantityLabel}`
              : prices[0].quantityMin === 1
              ? null
              : `${prices[0].quantityMin} + ${quantityLabel}`}
            <StatNumber>{getPriceString(prices[0].unitPrice)}</StatNumber>
            <StatHelpText>{`Per ${unitLabel}`}</StatHelpText>
          </Stat>
        )}
        {addOns.length && (
          <Center>
            <List spacing={5}>
              {addOns.map((addOn) => (
                <ListItem key={addOn.id} as={Center} justifyContent="left">
                  <>
                    <ListIcon fontSize="lg" as={AiOutlinePlusCircle} />
                    {`$${addOn.unitPrice} - ${addOn.unitLabel}`}
                  </>
                </ListItem>
              ))}
            </List>
          </Center>
        )}
        <Center>
          <Button
            as="a"
            href={product.purchaseUrl}
            leftIcon={<FaShoppingCart />}
          >
            Purchase
          </Button>
        </Center>
        <Center>
          <List spacing={5}>
            {features.map((feature) => (
              <ListItem key={feature.id} as={Center} justifyContent="left">
                <>
                  <ListIcon
                    fontSize="lg"
                    as={AiOutlineCheckCircle}
                    color="green.500"
                  />
                  {feature.description}
                </>
              </ListItem>
            ))}
            {nonFeatures.map((feature) => (
              <ListItem key={feature.id} as={Center} justifyContent="left">
                <>
                  <ListIcon
                    fontSize="lg"
                    as={AiOutlineCloseCircle}
                    color="red.500"
                  />
                  {feature.description}
                </>
              </ListItem>
            ))}
          </List>
        </Center>
      </Stack>
    </Card>
  );
};
