import {
  Box,
  Container,
  Heading,
  Link as ChakraLink,
  Stack,
} from "@chakra-ui/react";
import { ContactPreview, DarkMode } from "components";
import type { FooterFragment } from "generated";
import Image from "next/image";
import Link from "next/link";
import type { FunctionComponent } from "react";

export type FooterProps = FooterFragment;

export const Footer: FunctionComponent<FooterProps> = ({
  logo,
  pages,
  products,
  contactPreview,
}) => {
  const productLinks = products.map(({ id, name, page, slogan }) => (
    <Link key={id} href={`/${page.slug}`} passHref legacyBehavior>
      <ChakraLink
        color="secondary"
        title={slogan}
        fontWeight={600}
        fontSize="lg"
      >
        {name}
      </ChakraLink>
    </Link>
  ));
  const pageLinks = pages.map(({ id, name, slug, description }) => (
    <Link key={id} href={`/${slug}`} passHref legacyBehavior>
      <ChakraLink
        color="secondary"
        title={description}
        fontWeight={600}
        fontSize="lg"
      >
        {name}
      </ChakraLink>
    </Link>
  ));

  return (
    <DarkMode>
      <Box paddingY={14}>
        <Container>
          <Stack alignItems="center" spacing={10}>
            <Image
              src={logo.url}
              alt={logo.alt}
              title={logo.title}
              height={50}
              width={100}
              style={{
                filter: "grayscale(100%) brightness(100%)",
                opacity: 0.5,
              }}
            />
            <Stack
              spacing={{ base: 10, md: 40 }}
              direction={{ base: "column", sm: "row" }}
              justifyContent="space-evenly"
            >
              <Stack>
                <Heading>Products</Heading>
                {productLinks}
              </Stack>
              <Stack>
                <Heading>Pages</Heading>
                {pageLinks}
              </Stack>
            </Stack>
            <ContactPreview {...contactPreview[0]} />
          </Stack>
        </Container>
      </Box>
    </DarkMode>
  );
};
