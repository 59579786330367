import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Card, Heading, Stack, Text } from "@chakra-ui/react";
import type { FileFragment } from "generated";
import prettyBytes from "pretty-bytes";
import type { FunctionComponent } from "react";

export type FileProps = FileFragment;

export const File: FunctionComponent<FileProps> = ({ file, name, tagline }) => {
  return (
    <Card padding={5}>
      <Stack spacing={5} align="center">
        <Heading>{tagline}</Heading>
        <Stack align="center">
          <Button leftIcon={<DownloadIcon />} as="a" href={file.url}>
            {`Download ${name}`}
          </Button>
          <Text fontSize="sm" textAlign="center">{`${
            file.filename
          } - ${prettyBytes(file.size)}`}</Text>
        </Stack>
      </Stack>
    </Card>
  );
};
