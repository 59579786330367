import { Heading, Stack } from "@chakra-ui/react";
import type { PersonPreviewFragment } from "generated";
import Image from "next/image";
import type { FunctionComponent } from "react";
import type { StructuredTextGraphQlResponse } from "react-datocms";
import { StructuredText } from "react-datocms";
import { nodeRules } from "utilities";

export type PersonPreviewProps = PersonPreviewFragment;

export const PersonPreview: FunctionComponent<PersonPreviewProps> = ({
  person,
}) => {
  return (
    <Stack spacing={10} direction={{ base: "column", md: "row" }}>
      <Stack minWidth={{ base: "initial", md: 300 }} spacing={5} align="center">
        <Image
          src={person.image.url}
          alt={person.image.alt}
          title={person.image.title}
          width={500}
          height={500}
        />
      </Stack>
      <Stack spacing={5}>
        <Heading>{person.name}</Heading>
        <StructuredText
          customNodeRules={nodeRules}
          data={person.content.value as StructuredTextGraphQlResponse}
        />
      </Stack>
    </Stack>
  );
};
