import {
  Box,
  Button,
  Heading,
  HStack,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import type { ProductLinkFragment } from "generated";
import Image from "next/image";
import Link from "next/link";
import type { FunctionComponent } from "react";
import { FaBookOpen, FaQuestion, FaShoppingCart } from "react-icons/fa";

export interface ProductLinkProps extends ProductLinkFragment {
  hidePopup?: boolean;
}

export const ProductLink: FunctionComponent<ProductLinkProps> = ({
  name,
  color,
  logo,
  slogan,
  page,
  description,
  image,
  purchaseUrl,
  documentationUrl,
  hidePopup = false,
}) => {
  const productLink = (
    <Link href={`/${page.slug}`} passHref legacyBehavior>
      <ChakraLink
        color={color.hex}
        title={slogan}
        display="grid"
        gridTemplateColumns="30px auto"
        alignItems="center"
        gap={2}
        textStyle="product"
      >
        <Image src={logo.url} alt={logo.alt} width={30} height={30} />
        {name}
      </ChakraLink>
    </Link>
  );
  const productDetails = (
    <Stack spacing={10} align="center">
      <Heading>{slogan}</Heading>
      <HStack spacing={10} align="start">
        <Image
          src={image.url}
          alt={image.alt}
          title={image.title}
          width={300}
          height={300}
          style={{ objectFit: "contain" }}
        />
        <Stack spacing={10}>
          <Text>{description}</Text>
          <HStack alignSelf="center" spacing={5}>
            <Link href={`/${page.slug}`} passHref legacyBehavior>
              <Button as="a" leftIcon={<FaQuestion />}>
                About
              </Button>
            </Link>
            <Button as="a" leftIcon={<FaBookOpen />} href={documentationUrl}>
              Docs
            </Button>
            <Button as="a" leftIcon={<FaShoppingCart />} href={purchaseUrl}>
              Buy
            </Button>
          </HStack>
        </Stack>
      </HStack>
    </Stack>
  );

  return (
    <>
      <Show below="lg">{productLink}</Show>
      <Show above="lg">
        {hidePopup ? (
          productLink
        ) : (
          <Popover trigger="hover">
            <PopoverTrigger>
              <Box>{productLink}</Box>
            </PopoverTrigger>
            <PopoverContent width={1000}>
              <PopoverArrow />
              <PopoverBody padding={10}>{productDetails}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Show>
    </>
  );
};
