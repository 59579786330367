import { ChatIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import type { ContactFormFragment } from "generated";
import { contactRequestValidationSchema } from "pages/api/contact";
import { type FunctionComponent } from "react";

export type ContactFormProps = ContactFormFragment;

export const ContactForm: FunctionComponent<ContactFormProps> = ({
  name,
  description,
  errorTitle,
  successMessage,
  successTitle,
}) => {
  const toast = useToast();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    validationSchema: contactRequestValidationSchema,
    initialValues: {
      name: "",
      emailAddress: "",
      message: "",
    },
    onSubmit: (values) => {
      grecaptcha.ready(async () => {
        try {
          setSubmitting(true);
          const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

          if (!siteKey) {
            throw "Missing required environment variable NEXT_PUBLIC_RECAPTCHA_SITE_KEY";
          }

          const token = await grecaptcha.execute(siteKey, {
            action: "contact_form",
          });

          const response = await fetch("/api/contact", {
            method: "POST",
            body: JSON.stringify({ ...values, token }),
          });

          if (response.ok) {
            toast({
              title: successTitle,
              description: successMessage,
              status: "success",
            });

            resetForm();
          } else {
            toast({
              title: errorTitle,
              description: await response.text(),
              status: "error",
            });
          }
        } catch (e) {
          toast({
            title: errorTitle,
            status: "error",
          });
        } finally {
          setSubmitting(false);
        }
      });
    },
  });

  return (
    <Card padding={{ base: 5, md: 10 }}>
      <Stack spacing={10}>
        <Stack spacing={5}>
          <Heading>{name}</Heading>
          <Text>{description}</Text>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack spacing={5} align="start" width="100%">
            <FormControl isRequired isInvalid={!!errors.name && touched.name}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                name="name"
                placeholder="First Last"
                onChange={handleChange}
                onReset={handleReset}
                onBlur={handleBlur}
                value={values.name}
              />
              {!!errors.name && touched.name && (
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!errors.emailAddress && touched.emailAddress}
            >
              <FormLabel htmlFor="emailAddress">Email Address</FormLabel>
              <Input
                id="emailAddress"
                name="emailAddress"
                type="email"
                placeholder="firstlast@email.com"
                onChange={handleChange}
                onReset={handleReset}
                onBlur={handleBlur}
                value={values.emailAddress}
              />
              {!!errors.emailAddress && touched.emailAddress ? (
                <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>
              ) : (
                <FormHelperText>
                  We will not share your email address.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!errors.message && touched.message}
            >
              <FormLabel htmlFor="message">Message</FormLabel>
              <Textarea
                id="message"
                name="message"
                placeholder="Your message here."
                onChange={handleChange}
                onReset={handleReset}
                onBlur={handleBlur}
                value={values.message}
              />
              {!!errors.message && touched.message && (
                <FormErrorMessage>{errors.message}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              type="submit"
              isLoading={isSubmitting}
              leftIcon={<ChatIcon />}
              alignSelf="end"
            >
              Send Message
            </Button>
          </Stack>
        </form>
      </Stack>
    </Card>
  );
};
